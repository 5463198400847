<template>
  <v-container color="white" fluid class="ma-0 pa-0">
    {{config}}
    <Banner />
    <v-row  class="mt-10 ml-2 mr-2 mb-10">
      <v-col cols="8" class="text-center white--grey">
        <v-card color="grey" height="300">
          <v-card-title>banner 2</v-card-title>
        </v-card>
        <v-row class="mt-2">
          <v-col>
            <v-card color="#e5e5e5" height="300">
              <v-toolbar 
                color="#0aabbe"
                class="white--text"
              >Comunicados oficiais</v-toolbar>
              <v-card-text class="ma-2">
                <v-row class="ma-2">
                  <v-btn plain color="#0aabbe" class="font-weight-black subtitle-1">
                    <v-icon>mdi-arrow-right</v-icon> EXPEDIENTE
                  </v-btn>
                </v-row>
                <v-row class="ma-2">
                  <v-btn plain color="#0aabbe" class="font-weight-black subtitle-1">
                    <v-icon>mdi-arrow-right</v-icon> COVIDE
                  </v-btn>
                </v-row>
                <v-row class="ma-2">
                  <v-btn plain color="#0aabbe" class="font-weight-black subtitle-1">
                    <v-icon>mdi-arrow-right</v-icon> COMUNIDADO
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card color="#e5e5e5" height="300">
              <v-toolbar 
                color="#0aabbe"
                class="white--text"
              >Ideologias</v-toolbar>
              <v-card-text class="ma-2">
                <v-row class="ma-2">
                  <v-btn plain color="#0aabbe" class="font-weight-black subtitle-1">
                    <v-icon>mdi-arrow-right</v-icon> PROPÓSITO
                  </v-btn>
                </v-row>
                <v-row class="ma-2">
                  <v-btn plain color="#0aabbe" class="font-weight-black subtitle-1">
                    <v-icon>mdi-arrow-right</v-icon> MISSÃO
                  </v-btn>
                </v-row>
                <v-row class="ma-2">
                  <v-btn plain color="#0aabbe" class="font-weight-black subtitle-1">
                    <v-icon>mdi-arrow-right</v-icon> VISÃO
                  </v-btn>
                </v-row>                
                <v-row class="ma-2">
                  <v-btn plain color="#0aabbe" class="font-weight-black subtitle-1">
                    <v-icon>mdi-arrow-right</v-icon> VALORES
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="4" class="text-center white--grey">
        <v-row>
          <v-col>
            <v-card color="grey" height="180">
              <v-card-title>banner 3</v-card-title>
            </v-card>
          </v-col>
          <v-col>
            <v-card color="grey" height="180">
              <v-card-title>banner 4</v-card-title>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card color="grey" height="180">
              <v-card-title>banner 5</v-card-title>
            </v-card>
          </v-col>
          <v-col>
            <v-card color="grey" height="180">
              <v-card-title>banner 6</v-card-title>
            </v-card>
          </v-col>
        </v-row>
        
        <v-row>
          <v-col>
            <v-card color="#e5e5e5" height="211">
              <v-toolbar 
                color="#0aabbe"
                class="white--text"
              >Espaço do colaborador</v-toolbar>
              <v-card-text>
                <v-btn 
                  outlined
                  class="ma-2 pa-2" 
                  color="grey" 
                  v-for="i in ec" 
                  :key="`${i}a`">
                  <v-icon>{{i}}</v-icon>
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      
    </v-row>
    <v-row class="mt-10 ml-2 mr-2 mb-10">
      <v-col>
        <v-card>
          <v-card-title>ANIVERSARIANTES</v-card-title>
          <v-card-text>
            <v-row>
              <v-col v-for="i in 6" :key="i">
                <v-row>
                  <v-col>

                    <v-avatar color="grey">
                      Avatar
                    </v-avatar>
                  </v-col>
                  <v-col class="primary--text">
                    <div class="grey--text">Nome sobrenome</div>
                    <div>dd/mm</div>
                    <div>Setor</div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
  export default {
    name: 'Home',
    components:{
      Banner:()=>import('./Banner')
    },
    data: () => ({
      ec:['mdi-account-group', 'mdi-email-box','mdi-chat','mdi-bus','mdi-check'],
      config:JSON.parse(localStorage.getItem('cfg')),
     
    }),
    computed:{
      ...mapGetters([''])
    },
    mounted(){

    }
  }
</script>
